<template>
	<v-list two-line>
		<v-subheader>Rooms</v-subheader>

		<v-slide-x-transition group>
			<template v-for="(room, index) in list">
				<RoomListItem :room="room" :key="room.id" @click="$emit('click:room', room)"/>
				<v-divider v-if="index < list.length - 1" :key="index"></v-divider>
			</template>
		</v-slide-x-transition>
	</v-list>
</template>

<script>
import RoomListItem from "./RoomListItem";

export default {
	name: "RoomList",
	components: { RoomListItem },
	props: {
		list: { type:Array },
	},
	emits: ["click:room"],
}
</script>
