import { writeBatch, arrayRemove } from "firebase/firestore";
import { db } from "@/services/firebase";

export const DeleteAccountAction = (tenantSnapshot, residenceSnapshot) => {
	if(!tenantSnapshot) throw new Error("Missing tenant!!!");

	return {
		invoke: (tenants) => {
			const batch = writeBatch(db);

			if(residenceSnapshot) batch.update(residenceSnapshot.ref, {tenants: arrayRemove(tenantSnapshot.id)});
			batch.delete(tenantSnapshot.ref);

			return batch.commit()
				.then(() => {
					const index = tenants.findIndex(el => el.id === tenantSnapshot.id);
					if(index != -1) {
						tenants.splice(index, 1);
						return [...tenants];
					}
					return tenants;
				});
		},
	};

};
DeleteAccountAction.TYPE = "deleteAccount";
