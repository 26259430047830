<template>
	<v-list-item @click="$emit('click', $event)">
		<template v-slot:default>
			<v-list-item-content>
				<v-list-item-title v-text="name"></v-list-item-title>

				<v-list-item-subtitle
					class="text--primary"
					v-text="value.email"
				></v-list-item-subtitle>

				<template v-if="value.registered !== true">
					<v-list-item-subtitle v-if="expired">Expired {{expiration}}</v-list-item-subtitle>
					<v-list-item-subtitle v-else>Expires {{expiration}}</v-list-item-subtitle>
				</template>
				<v-list-item-subtitle v-else-if="registeredAt">Registered {{registeredAt}}</v-list-item-subtitle>

			</v-list-item-content>

			<v-list-item-action>
				<div class="mr-2">
					<v-icon v-if="value.registered" size="28" color="green darken-2">mdi-check</v-icon>
					<v-icon v-else-if="expired" size="28" color="yellow darken-2">mdi-alert</v-icon>
					<v-icon v-else-if="value.invitation_sent" size="28" color="grey lighten-1">mdi-email-fast</v-icon>
					<v-progress-circular v-else indeterminate />
				</div>
			</v-list-item-action>
		</template>
	</v-list-item>
</template>

<script>
import { addHours, isBefore, formatDistanceToNow } from "date-fns";
import { computed } from "@vue/composition-api";

export default {
	name: "InvitationListItem",
	components: { },
	props: {
		value: { type:Object, required:true },
	},
	emits: ["click"],

	setup(props) {
		const expirationDate = addHours(new Date(props.value.timestamp), 6);

		const name = computed(() => `${props.value.name?.first || ""} ${props.value.name?.last || ""}`);
		const expiration = computed(() => formatDistanceToNow(expirationDate, { addSuffix:true }));
		const expired = computed(() => isBefore(expirationDate, new Date()));
		const registeredAt = computed(() => {
			const time = props.value.registration_time;
			if(!time) return;
			return formatDistanceToNow(new Date(time), { addSuffix:true });
		});

		return { name, expiration, expired, registeredAt };
	}
}
</script>
