<template>
	<v-app-bar app class="app-selection-header">
		<v-app-bar-nav-icon @click="$emit('clear:clicked', $event)" :hover="false">
			<v-icon>mdi-close</v-icon>
		</v-app-bar-nav-icon>

		<v-toolbar-title>{{title}}</v-toolbar-title>

		<v-spacer/>

		<slot name="header-options"></slot>
	</v-app-bar>
</template>

<script>
export default {
	name: "AppSelectionHeader",
	props: { title:String },
	emits: ["clear:clicked"],

	setup() {
		return { };
	},
};
</script>
