import { ref, computed } from "@vue/composition-api";
import useEventListener from "./useEventListener";


export default function({offlineDelay} = {offlineDelay:0}) {
	const online = ref(navigator.onLine);
	const setOnline = () => online.value = true;
	const setOffline = async () => online.value = false;

	useEventListener(window, "online", setOnline);
	useEventListener(window, "offline", () => {
		if(offlineDelay <= 0) setOffline();
		else checkOfflineAfterDelay(offlineDelay, setOffline);
	});

	return { connected: computed(() => online.value) };
}

function checkOfflineAfterDelay(delay, callback) {
	setTimeout(() => {
		if(navigator.onLine !== false) return;
		callback();
	}, delay);
}
