<template>
	<v-dialog persistent
		v-model="dialog"
		:fullscreen="$vuetify.breakpoint.smAndDown"
		:hide-overlay="$vuetify.breakpoint.smAndDown"
		:max-width="maxWidth"
		overlay-color="grey darken-3"
	>
		<v-card>
			<v-form
				ref="form"
				v-model="valid"
				:disabled="disabled"
				@submit.prevent="validate() && $emit('save', model); $router.back();"
			>
				<v-toolbar dark>
					<v-btn icon dark @click="$router.back()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Invite Tenant</v-toolbar-title>
					<v-spacer/>
					<v-btn v-if="$vuetify.breakpoint.smAndDown"
						text dark
						type="submit"
						:disabled="!valid || !connected"
						:loading="!dialog"
						color="primary"
					> Save </v-btn>
				</v-toolbar>

				<v-slide-y-transition group leave-absolute>
					<v-system-bar window v-if="!connected" color="warning" key="offline">
						<span class="subtitle-2 grey--text text--darken-4">Network offline. Tenant invitations disabled.</span>
					</v-system-bar>
					<v-system-bar window v-else color="transparent" key="online"></v-system-bar>
				</v-slide-y-transition>

				<v-card-text class="pt-6">
					<v-text-field required outlined
						v-model.trim="fname"
						:rules="fnameRules"
						label="First Name *"
						prepend-icon="mdi-account"
					>
						<template v-slot:append>
							<InputClearableBtn v-model="fname" :disabled="disabled"/>
						</template>
					</v-text-field>

					<v-text-field required outlined
						v-model.trim="lname"
						:rules="lnameRules"
						label="Last Name *"
						class="no-label"
					>
						<template v-slot:append>
							<InputClearableBtn v-model="lname" :disabled="disabled"/>
						</template>
					</v-text-field>

					<v-text-field required outlined
						type="email"
						v-model.trim="email"
						:rules="emailRules"
						label="E-mail *"
						prepend-icon="mdi-email"
					>
						<template v-slot:append>
							<InputClearableBtn v-model="email" :disabled="disabled"/>
						</template>
					</v-text-field>

					<v-autocomplete outlined
						label="Room"
						prepend-icon="mdi-door"
						v-model="residence"
						:items="rooms"
						:disabled="loadingRooms"
						:loading="loadingRooms"
						item-text="name"
						item-value="id"
						clearable
					>
						<template v-slot:append-outer>
							<v-icon
								v-text="'mdi-reload'"
								:disabled="loadingRooms"
								@click="loadRooms()"
							/>
						</template>
					</v-autocomplete>

				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn v-if="$vuetify.breakpoint.mdAndUp"
						text dark
						type="submit"
						:disabled="!valid || !connected"
						:loading="!dialog"
						color="primary"
					> Save </v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
import { ref, toRefs, reactive, computed } from "@vue/composition-api";
import InputClearableBtn from "@/components/InputClearableBtn";
import useResidencesList from "@/composables/useResidencesList";
import useDisableDocumentScrollFix from "@/composables/useDisableDocumentScrollFix";
import useConnection from "@/composables/useConnection";
import settings from "@/config/settings";

const nameRules = (label) => [
	v => !!v && v.trim().length > 0 || `${label} is required`,
];

export default {
	name: "InviteTenantDialog",
	components: { InputClearableBtn },
	props: {},

	beforeRouteLeave(to, from, next) {
		this.dialog = false;
		setTimeout(next, 250);
	},

	setup(props, {root}) {
		const { list:roomList, load:loadRooms, loading:loadingRooms } = useResidencesList();
		const { connected } = useConnection({offlineDelay:250});
		const form = ref(null);

		const dialog = ref(true);
		useDisableDocumentScrollFix(() => dialog.value, {immediate:true});

		const data = reactive({
			valid: true,

			fname: "",
			fnameRules: nameRules("First Name"),

			lname: "",
			lnameRules: nameRules("Last Name"),

			email: "",
			emailRules: [
				v => !!v || "E-mail is required",
				v => /.+@.+\..+/.test(v) || "E-mail must be valid",
			],

			rooms: computed(() => {
				const toData = room => ({...room.data(), id:room.id});
				return roomList.value.map(toData);
			}),
			residence: null,
			loadingRooms,
			disabled: computed(() => !dialog.value || !connected.value),
		});

		const methods = {
			validate: () => form.value.validate(),
			reset: () => form.value && form.value.reset(),
			loadRooms,
		};

		const model = computed(() => {
			return {
				fname: data.fname,
				lname: data.lname,
				email: data.email,
				residence: data.residence,
			}
		});

		const maxWidth = computed(() => {
			return settings.dialog.widths[root.$vuetify.breakpoint.name];
		});

		if(roomList.value.length === 0) loadRooms();

		return { connected, form, dialog, ...toRefs(data), ...methods, model, maxWidth };
	}
}
</script>

<style lang="scss" scoped>
	.no-label {
		margin-left: 33px;
	}

	.v-toolbar {
		z-index: 10;
	}

	.v-system-bar {
		z-index: 5;
	}
</style>
