import { writeBatch, arrayRemove } from "firebase/firestore";
import { db } from "@/services/firebase";

export const RemoveUserFromResidenceAction = (tenantSnapshot, residenceSnapshot) => {
	if(!tenantSnapshot) throw new Error("Missing tenant!!!");
	if(!residenceSnapshot) throw new Error("Missing residence!!!");

	return {
		invoke: (tenants) => {
			const batch = writeBatch(db);

			batch.update(tenantSnapshot.ref, {residence:null});
			batch.update(residenceSnapshot.ref, {tenants: arrayRemove(tenantSnapshot.id)});

			return batch.commit()
				.then(() => {
					const user = tenants.find(el => el.id === tenantSnapshot.id);
					if(user) {
						user.residence = null;
						return [...tenants];
					}
					return tenants;
				});
		},
	};

};
RemoveUserFromResidenceAction.TYPE = "removeFromResidence";
