<template>
	<div>
		<portal to="application-header">
			<v-fade-transition v-if="selecting">
				<AppSelectionHeader :title="selectionTitle" @clear:clicked="$emit('clear:selection')">
					<template v-slot:header-options>
						<v-btn icon>
							<v-icon>mdi-dots-vertical</v-icon>
						</v-btn>
					</template>
				</AppSelectionHeader>
			</v-fade-transition>

			<v-fade-transition v-else-if="state.searching">
				<AppSearchHeader :searchText.sync="state.searchText" @back:clicked="state.searching = false; state.searchText = ''"/>
			</v-fade-transition>
		</portal>

		<portal to="header-options">
			<v-scale-transition origin="center">
				<template v-if="!searching">
					<v-btn icon @click="state.searching = true">
						<v-icon>mdi-magnify</v-icon>
					</v-btn>
				</template>
			</v-scale-transition>
		</portal>

		<portal to="header-extension">
			<v-scale-transition origin="center">
				<slot name="extension"></slot>
			</v-scale-transition>
		</portal>
	</div>
</template>

<script>
import { reactive } from "@vue/composition-api";
import { useModelWrapper } from "@/logic/model-wrapper";

import AppSearchHeader from "@/components/app/AppSearchHeader";
import AppSelectionHeader from "@/components/app/AppSelectionHeader";

export default {
	name: "AdminHeader",
	components: { AppSearchHeader, AppSelectionHeader, },
	props: {
		selecting: { type:Boolean, required:true },
		selectionTitle: { type:String, required:true },
		searching: { type:Boolean, required:true },
		searchText: { type:String, default:"" },
	},

	setup(props, {emit}) {
		const state = reactive({
			searching: useModelWrapper(props, emit, "searching"),
			searchText: useModelWrapper(props, emit, "searchText"),
		});

		return { state };
	}
}
</script>
