<template>
	<v-dialog
		:value="open"
		:fullscreen="$vuetify.breakpoint.smAndDown"
		:hide-overlay="$vuetify.breakpoint.smAndDown"
		overlay-color="grey darken-2"
		:no-click-animation="$vuetify.breakpoint.smAndDown"
		:max-width="maxWidth"
		@click:outside="$emit('close')"
		@keydown.esc="$emit('close')"
	>
		<v-card v-if="value">
			<v-toolbar flat dark>
				<v-btn icon dark @click="$emit('close')">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>

			<v-card-title class="text-h4 mt-6">Invitation Status</v-card-title>
			<v-card-subtitle class="mt-1 text-body-1">{{name}} {{email}}</v-card-subtitle>

			<v-card-text class="pl-0 pr-1">
				<v-list>
					<v-list-item v-for="({label, value, indeterminate, color}, i) in checkboxes" :key="i">
						<template v-slot:default>
							<v-list-item-content>
								<v-list-item-title>{{label}}</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action>
								<v-checkbox readonly
									:input-value="value"
									:indeterminate="indeterminate"
									:color="color"
									:ripple="false"
								/>
							</v-list-item-action>
						</template>
					</v-list-item>

					<v-list-item v-if="!value.registered">
						<v-list-item-content>
							<v-list-item-title>Expiration</v-list-item-title>
							<v-list-item-subtitle>{{expiration}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-list-item v-else-if="registeredAt">
						<v-list-item-content>
							<v-list-item-title>Registration Date</v-list-item-title>
							<v-list-item-subtitle>{{registeredAt}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-card-text>
		</v-card>
		<!--
		TODO: add resend option for expired invitations (add issue)
		-->
	</v-dialog>
</template>

<script>
import { addHours, isBefore, format } from "date-fns";
import {computed} from "@vue/composition-api";
import settings from "@/config/settings";

export default {
	name: "InvitationDetailsDialog",
	props: {
		value: { required:true },
		open: { type:Boolean, required:true }
	},
	emits: ["close"],


	setup(props, {root}) {
		const checkboxes = computed(() => {
			if(!props.value) return [];
			const invitationSent = props.value.invitation_sent === true;
			const registered = props.value.registered === true;
			const expiration = addHours(new Date(props.value.timestamp), 6);
			const isExpired = isBefore(expiration, new Date());

			const getColor = value => value ? "green" : "grey";

			const result = [
				{
					label: "Invitation Sent",
					value: invitationSent,
					indeterminate: !invitationSent && isExpired,
					color: getColor(invitationSent),

				},
				{
					label: "Registration Complete",
					value: registered,
					indeterminate: !registered && isExpired,
					color: getColor(registered),
				},
			];
			return result;
		});
		const expiration = computed(() => {
			if(!props.value) return;
			const expirationDate = addHours(new Date(props.value.timestamp), 6);
			return format(expirationDate, "EEEE, MMMM do yyyy, h:mm a");
		});
		const name = computed(() => props.value ? `${props.value.name.first} ${props.value.name.last}` : "N/A")
		const email = computed(() => props.value ? `<${props.value.email}>` : []);
		const maxWidth = computed(() => settings.dialog.widths[root.$vuetify.breakpoint.name]);
		const registeredAt = computed(() => {
			const time = props.value?.registration_time;
			if(!time) return;
			return format(new Date(time),  "EEEE, MMMM do yyyy, h:mm a");
		});
		return { name, email, checkboxes, maxWidth, expiration, registeredAt };
	}
}
</script>
