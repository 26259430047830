<template>
	<v-row no-gutters>
		<v-col cols="12">
			<v-text-field solo hide-details flat clearable
				ref="textbox"
				placeholder="Search"
				color="white"
				background-color="#272727"
				prepend-inner-icon="mdi-arrow-left"
				@click:prepend-inner="$emit('back:clicked', $event)"
				v-model="search"
			></v-text-field>
		</v-col>
	</v-row>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api"
import { useModelWrapper } from "@/logic/model-wrapper";

export default {
	name: "SearchToolbar",
	props: { searchText:String },
	emits: ["update:searchText", "back:clicked"],

	setup(props, {emit}) {
		const textbox = ref(null);
		const search = useModelWrapper(props, emit, "searchText");

		onMounted(() => textbox.value.focus());

		return { search, textbox };
	},
};
</script>
