<template>
	<v-dialog persistent
		:max-width="maxWidth"
		:value="open"
		@keydown="!busy && $emit('close')"
	>
		<v-form @submit.prevent="valid && $emit('confirm'); text=''">
			<v-card :loading="busy">
				<template slot="progress">
					<v-progress-linear indeterminate color="primary"/>
				</template>

				<v-card-title class="headline">
					<slot name="title"></slot>
				</v-card-title>

				<v-card-text>
					<slot name="text"></slot>
					<v-text-field required
						ref="input"
						v-model="text"
						:rules="rules"
					/>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text
						color="primary"
						:disabled="busy"
						@click="$emit('cancel'); text = ''"
					> {{noText}} </v-btn>
					<v-btn text
						color="primary"
						type="submit"
						:disabled="busy || !valid"
					> {{yesText}} </v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>
import { ref, computed, watch } from "@vue/composition-api"
import settings from "@/config/settings";

export default {
	name: "ConfirmationInputDialog",
	props: {
		open: { type:Boolean, required:true },
		busy: { type:Boolean, default:false },
		yesText: { type:String, default:"Yes" },
		noText: { type:String, default:"No" },
		matchText: { type:String, required:true },
	},
	emits: [ "cancel", "confirm" ],

	setup(props, {root}) {
		const input = ref(null);
		const text = ref("");
		const valid = computed(() => text.value === props.matchText);
		const rules = [
			value => !!value || "Required.",
			value => value === props.matchText || `Text should match '${props.matchText}'`
		];
		const maxWidth = computed(() => {
			return settings.confirmation.widths[root.$vuetify.breakpoint.name];
		});
		watch(() => props.open, (open) => {
			if(!open) return;
			setTimeout(() => input.value.focus(), 150);
		})
		return { input, text, maxWidth, valid, rules };
	}
}
</script>
