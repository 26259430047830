<template>
	<div class="fill-height d-flex flex-column flex-grow-1">
		<AdminHeader
			:selecting="tenants.selected != -1"
			selectionTitle=""
			:searchText.sync="searchText"
			:searching.sync="searching"
			@clear:selection="clearSelection"
		>
			<template v-slot:extension>
				<v-tabs v-model="tab">
					<v-tab>Tenants</v-tab>
					<v-tab>Invitations</v-tab>
				</v-tabs>
			</template>
		</AdminHeader>

		<v-tabs-items v-model="tab" class="d-flex flex-grow-1 align-stretch" :touchless="searching">
			<!-- Tenants tab -->
			<v-tab-item>
				<TenantList
					:list="tenants.filtered"
					:loading="tenants.loading"
					@click:tenant="setCurrentTenant($event); addUserQuery(); openProfile = true;"
					v-page-bottom="loadMoreUsers"
				>
					<template v-slot:no-items>
						<div class="d-flex flex-column flex-grow-1 align-center justify-sm-center mt-16" :class="{ 'pt-4': $vuetify.breakpoint.xs }">
							<div class="d-flex no-results-image-wrapper">
								<v-img contain :src="require('@/assets/NoSearchResult.svg')"/>
							</div>
							<h4 align="center" class="text-h4 text--secondary">No results found</h4>
						</div>
					</template>
				</TenantList>
			</v-tab-item>

			<!-- Invitations tab -->
			<v-tab-item>
				<!-- Empty state -->
				<div v-if="!invitations.loading && invitations.filtered.length === 0"
					class="d-flex flex-column flex-grow-1 align-center justify-sm-center mt-16"
					:class="{ 'pt-4': $vuetify.breakpoint.xs }"
				>
					<div class="d-flex no-results-image-wrapper">
						<v-img contain :src="require('@/assets/NoSearchResult.svg')"/>
					</div>
					<h4 align="center" class="text-h4 text--secondary">No recent invitations found</h4>
				</div>

				<v-list v-else two-line class="flex-grow-1">
					<v-slide-x-transition group>
						<template v-for="(invitation, index) in invitations.filtered">
							<InvitationListItem
								:key="invitation.key"
								:value="invitation"
								@click="invitations.current = invitation;"
							>
							</InvitationListItem>
							<v-divider :key="index"></v-divider>
						</template>
					</v-slide-x-transition>

					<!-- Loading Feedback -->
					<template v-if="invitations.loading">
						<template v-for="n in 8">
							<v-skeleton-loader
								:key="`skel-${n}`"
								max-width="200px"
								type="list-item-two-line"
								class="py-n2"
							/>
							<v-divider v-if="n < 8" :key="n"></v-divider>
						</template>
					</template>
				</v-list>

			</v-tab-item>
		</v-tabs-items>

		<router-view name="invite" @save="inviteTenant"/>

		<TenantProfileDialog
			:open="openProfile"
			:tenant="tenants.current"
			:rooms="rooms.list"
			@close="removeUserQuery(); openProfile = false;"
			@update:user="setCurrentTenant"
		/>

		<InvitationDetailsDialog
			:open="invitations.current !== null"
			:value="invitations.current"
			@close="invitations.current = null"
		/>

	</div>
</template>

<script>
import { ref, reactive, toRefs, watch, watchEffect, computed, onMounted, onUnmounted } from "@vue/composition-api";

import useFab from "@/composables/useFab";
import useSnackbar, { CLOSE_ACTION } from "@/composables/useSnackbar";
import useSyncQueryParameter from "@/composables/useSyncQueryParameter";

import AdminHeader from "@/components/admin/AdminHeader";
import TenantList from "@/components/tenant/TenantList";
import TenantProfileDialog from "@/components/tenant/TenantProfileDialog";
import InvitationListItem from "@/components/invitations/InvitationListItem"
import InvitationDetailsDialog from "@/components/invitations/InvitationDetailsDialog"

import { inviteUser } from "@/services/firebase";
import useUser from "@/composables/useUser";
import { useTenants } from "@/composables/tenants";
import useInvitations from "@/composables/useInvitations";
import useResidencesList from "@/composables/useResidencesList";

import settings from "@/config/settings";

//TODO Move to useTenants?
function filterTenants(list, search) {
	if(!search) return list;

	const regex = new RegExp(`.*${search.trim()}.*`, "i");
	return list.filter( item => {
		const {first, last} = item.name;
		return regex.test(first) || regex.test(last) || regex.test(item.residence?.name);
	});
}

//TODO Move to useInvitations?
function filterInvitations(list, search) {
	if(!search) return list;

	const regex = new RegExp(`.*${search.trim()}.*`, "i");
	return list.filter( item => {
		const {first, last} = item.name;
		return regex.test(first) || regex.test(last) || regex.test(item.email);
	});
}


export default {
	name: "Admin",
	components: { AdminHeader, InvitationDetailsDialog, InvitationListItem, TenantList, TenantProfileDialog },

	setup(_, { root }) {
		const { list:siteResidences, load:loadResidences, onChange$ } = useResidencesList();
		const { adminSite } = useUser();
		const { list, load:loadUsers, get:getUser, search:searchTenants } = useTenants();
		const { invitations, load:loadInvitations } = useInvitations();

		const firstLoad = ref(true);

		const fab = useFab();
		fab.setListener({
			click: () => root.$router.push({ name:"InviteTenant" }),
		});
		const snackbar = useSnackbar();

		const state = reactive({
			selectingItems: computed(() => state.tenants.selected.length),
			searching: false,
			searchText: "",
			tab: null,

			tenants: {
				list: list,
				loading: computed(() => !state.mounted || firstLoad.value || state.loadingUsers),
				filtered: computed(() => filterTenants(state.tenants.list, state.searchText)),
				selected: -1,
				current: null,
			},

			invitations: {
				list: invitations,
				loading: computed(() => !state.mounted || firstLoad.value || !state.invitations.fetched),
				filtered: computed(() => filterInvitations(state.invitations.list, state.searchText)),
				current: null,
				fetched: false,
			},

			mounted: false,
			loadingUsers: false,

			openProfile: "user" in root.$route.query,
			showBottomNav: computed(() => !state.searching && !state.tenants.selected.length),

			rooms: {
				list: siteResidences,
			},
		});


		let allTenantsLoaded = false;
		const methods = {
			clearSelection: () => {
				state.tenants.selected = [];
			},

			inviteTenant: (tenant) => {
				const {residence, email} = tenant;
				const name = { first:tenant.fname, last:tenant.lname };
				const site_id = adminSite.value;
				const data = { site_id, email, name, residence_id:residence };
				return inviteUser(data).then(() => {
					snackbar.show("Invitation sent", {action:CLOSE_ACTION});
				}).catch(() => {
					snackbar.show("Failed to send invitation!", {action:CLOSE_ACTION});
				});
			},


			setCurrentTenant: (user) => {
				const tenant = state.tenants.list.find(ten => ten.id === user.id);
				if(!tenant) return;
				state.tenants.current = tenant;
			},

			loadMoreUsers: () => {
				if(state.loadingUsers) return;
				if(allTenantsLoaded) return;

				const lastCount = state.tenants.list.length;
				state.loadingUsers = true;
				return loadUsers({count:settings.tenants.querySize})
					.then(() => {
						const diff = state.tenants.list.length - lastCount;
						allTenantsLoaded = diff <= 0;
					})
					.finally(() => state.loadingUsers = false)
			},

			addUserQuery() {
				const router = root.$router;
				router.push({ path: "/admin", query: { user: state.tenants.current.id } });
			},

			removeUserQuery() {
				const router = root.$router;
				router.replace({ path: "/admin" });
			},

			fetchInvitations() {
				if(state.invitations.fetched) return;
				state.invitations.fetched = true;
				loadInvitations();
			}
		};


		onMounted(() => {
			state.mounted = true;
			if(list.value.length === 0) {
				// Determine how many tenants we'd need to fill up the viewport
				const windowHeight = window.innerHeight;
				const tenantItemSize = 64;
				const minimumQuerySize = Math.floor(windowHeight / tenantItemSize) + 3; // add a few just to be safe
				loadUsers({count:minimumQuerySize}).finally(() => firstLoad.value = false);
			}else{
				firstLoad.value = false;
			}
			loadResidences();

			// Load user if the user id exists in the query parameters
			const userId = root.$route.query.user;
			const hasUserId = userId && userId.length;
			if(hasUserId) {
				state.openProfile = true;
				getUser(userId).then(user => {
					state.tenants.current = user;
				}).catch(err => {
					state.openProfile = false;
					console.error(err);
				});
			}
		});

		// This will keep room changes in sync between clients without having to snapshot each user
		// ie Client 1 adds User A to Room 1, Client 2 will see changes to User A's room
		const unsubscribe = onChange$.subscribe(({before, after}) => {
			if(before.tenants.length) {
				let tenant = state.tenants.list.find(tenant => before.tenants.includes(tenant.id));
				if(tenant) tenant.residence = null;
			}

			if(after.tenants.length) {
				let tenant = state.tenants.list.find(tenant => after.tenants.includes(tenant.id));
				if(tenant) tenant.residence = { name: after.name };
			}
		});

		onUnmounted(unsubscribe);

		useSyncQueryParameter(root.$router, "user", (exists, value) => {
			state.openProfile = exists;
			if(exists && value.length) {
				const tenant = state.tenants.list.find(ten => ten.id === value);
				state.tenants.current = tenant;
			}
		});

		watchEffect(() => {
			if(root.$route.path !== "/admin") return;
			const hideFab = state.selectingItems || state.searching || state.openProfile || state.invitations.current !== null;
			if(hideFab) fab.hide();
			else fab.show();
		});

		// Defer loading invitation snapshot until we're on the invitation tab
		const handle = watch(() => state.tab, (value) => {
			if(value != 1) return;
			if(state.invitations.fetched) return;
			state.invitations.fetched = true;
			loadInvitations();
			handle();
		});

		let debounceHandle;
		watch(() => state.searchText, (value) => {
			if(!value || state.tab !== 0) return;
			clearTimeout(debounceHandle);
			state.loadingUsers = true;
			debounceHandle = setTimeout(() => {
				searchTenants(value).finally(() => state.loadingUsers = false);
			}, 1000);
		});

		return { ...toRefs(state), ...methods, log:console.log };
	}
}
</script>

<style lang="scss">
	@import "@/styles/local.scss";

	.no-results-image-wrapper {
		height: 38vh;
		width: 38vh;
		border-radius: 50%;
		background-color: white;

		+ h4 {
			margin-top: 8vh;
		}
	}

	.v-tabs-bar {
		background-color: $app-header-color !important;
	}

	.v-tabs-items {
		.v-window__container {
			flex: 1 1 0%;
		}
	}
</style>
