import { watch, watchEffect } from "@vue/composition-api";

// Applies the .overflow-y-hidden class to the document <html> tag. This class prevents
// the main content from scrolling (such as the Tenant list). Typically a dialog's overlay
// will handle applying this but it seems somewhat buggy. Bugs were noticed from the Admin
// view:
// 1. The first time a tenant is opened, no class is applied, but is on subsequent "opens"
// 2. The class is toggled when navigating to the Account Settings dialog that is nested
//    in the tenant view
export default function(shouldDisableFn, {immediate, delay}={immediate:false, delay:0}) {
	if(!immediate) {
		watch(shouldDisableFn, (shouldDisable) => {
			if(shouldDisable) addDisableScrollClass(delay);
		})
	} else {
		watchEffect(() => {
			const shouldDisable = shouldDisableFn();
			if(shouldDisable) addDisableScrollClass(delay);
		});
	}
}

function addDisableScrollClass(delay=0) {
	const addClass = () => document.documentElement.classList.add("overflow-y-hidden");
	if(delay) setTimeout(addClass, delay);
	else addClass();
}
