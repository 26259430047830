<template>
	<v-app-bar app class="app-search-header">
		<SearchToolbar
			:searchText.sync="search"
			@back:clicked="$emit('back:clicked', $event)"
		/>
	</v-app-bar>
</template>

<script>
import { useModelWrapper } from "@/logic/model-wrapper";
import SearchToolbar from "@/components/SearchToolbar";

export default {
	name: "AppHeaderSearch",
	props: { searchText:String },
	emits: ["update:searchText", "back:clicked"],
	components: { SearchToolbar },

	setup(props, {emit}) {
		const search = useModelWrapper(props, emit, "searchText");
		return { search };
	},
};
</script>

<style lang="scss">
	.app-search-header {
		.v-toolbar__content {
			padding-left: 0;
			padding-right: 0;
		}
	}
</style>
