<template>
	<div>
		<div class="avatar-container d-flex justify-center align-center">
			<v-avatar size="84" color="primary">
				<h1 class="font-weight-light">{{initials}}</h1>
			</v-avatar>
		</div>

		<v-card-title class="text-h5">{{name}}</v-card-title>
		<v-divider/>

		<v-list class="pt-6">
			<v-list-item two-line class="pl-5 pr-8" color="success">
				<v-list-item-content>
					<v-list-item-title> Account Settings </v-list-item-title>
					<v-list-item-subtitle> {{ statusText }} </v-list-item-subtitle>
				</v-list-item-content>

				<v-divider vertical class="mx-4 my-2"></v-divider>
				<v-list-item-action>
					<v-icon color="primary" @click="showAccountSettings = true">mdi-cog</v-icon>
				</v-list-item-action>
			</v-list-item>

			<v-list-item>
				<v-list-item-icon>
					<v-icon>mdi-door</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title v-if="tenant.residence">
						{{ tenant.residence.name }}
					</v-list-item-title>
					<v-list-item-title v-else class="text--secondary">
						No Room Assigned
					</v-list-item-title>
				</v-list-item-content>

				<v-list-item-action>
					<slot name="room-actions"></slot>
				</v-list-item-action>
			</v-list-item>

			<v-list-item>
				<v-list-item-icon>
					<v-icon>mdi-security</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>
						Permissions
					</v-list-item-title>
				</v-list-item-content>

				<v-list-item-action>
					<span v-if="isAdmin" class="text-body-1 mr-4">Site Admin</span>
					<v-btn v-else
						data-cy="elevate-account"
						color="warning"
						@click="$emit('click:elevate-admin')"
					> Elevate to admin </v-btn>
				</v-list-item-action>
			</v-list-item>


			<v-list-item></v-list-item>
		</v-list>


		<!-- Account Settings Dialog -->
		<v-dialog
			v-model="showAccountSettings"
			:fullscreen="$vuetify.breakpoint.smAndDown"
			:hide-overlay="$vuetify.breakpoint.smAndDown"
			overlay-color="grey darken-2"
			:no-click-animation="$vuetify.breakpoint.smAndDown"
			:max-width="maxWidth"
			content-class="tenant-account-settings-dialog"
		>
			<v-card tile flat :min-height="$vuetify.breakpoint.mdAndUp ? '55vh' : undefined" class="d-flex flex-column">
				<v-toolbar flat dark :max-height="$vuetify.breakpoint.mdAndUp ? '64' : '56'">
					<v-btn icon dark @click="showAccountSettings = false; $router.back()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-toolbar>

				<!-- Network offline notification -->
				<v-slide-y-transition group leave-absolute>
					<v-system-bar window v-if="!connected" color="warning" key="offline">
						<span class="subtitle-2 grey--text text--darken-4">Network offline. Some functionality disabled.</span>
					</v-system-bar>
					<v-system-bar window v-else color="transparent" key="online"></v-system-bar>
				</v-slide-y-transition>

				<v-card-title class="text-h4 mt-6">Account Settings</v-card-title>
				<v-card-subtitle class="mt-1 text-body-1">{{name}}</v-card-subtitle>

				<v-card-text>
					<v-list class="mt-10">
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>Account Status</v-list-item-title>
								<v-list-item-subtitle>{{ statusText }}</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
								<v-tooltip bottom allow-overflow :disabled="!isSelf">
									<template v-slot:activator="{ on }">
										<div v-on="on">
											<v-btn v-if="active"
												color="warning darken-1"
												:disabled="disabled || isSelf || !connected"
												:loading="busy"
												@click="$emit('click:disable')"
											> Disable </v-btn>
											<v-btn v-else
												color="success"
												:disabled="disabled || isSelf || !connected"
												:loading="busy"
												@click="$emit('click:enable')"
											> Enable </v-btn>
										</div>
									</template>
									<span> Cannot change your own account status </span>
								</v-tooltip>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-card-text>

				<v-card-actions class="d-flex-flex-column flex-grow-1 align-end">
					<v-tooltip top :disabled="!isSelf">
						<template v-slot:activator="{ on }">
							<div v-on="on" class="d-flex flex-grow-1">
								<v-btn block :disabled="disabled || isSelf || !connected" color="error" class="mb-4" @click="$emit('click:delete')"> Delete Account </v-btn>
							</div>
						</template>
						<span> Cannot delete your own account </span>
					</v-tooltip>
				</v-card-actions>
			</v-card>

		</v-dialog>
	</div>
</template>

<script>
import { reactive, toRefs, computed, watch } from "@vue/composition-api";
import useUser from "@/composables/useUser";
import useConnection from "@/composables/useConnection";
import usePruneQueryParameter from "@/composables/usePruneQueryParameter";
import useSyncQueryParameter from "@/composables/useSyncQueryParameter";
import useDisableDocumentScrollFix from "@/composables/useDisableDocumentScrollFix";
import settings from "@/config/settings";

export default {
	name: "TenantProfile",
	components: {},
	props: {
		tenant: { type:Object },
		isAdmin: { type:Boolean, default:false },
		disabled: { type:Boolean, default:false },
		busy: { type:Boolean, default:false },
	},
	emits: ["click:enable", "click:disable", "click:delete", "click:elevate-admin"],

	setup(props, {root}) {
		const { user } = useUser();
		const { connected } = useConnection({offlineDelay:250});
		const state = reactive({
			connected,
			showAccountSettings: false,

			active: computed(() => {
				if(props.tenant.status === undefined) return true;
				return props.tenant.status.enabled;
			}),
			statusText: computed(() => {
				if(props.tenant.status === undefined) return "Active";
				return props.tenant.status.enabled ? "Active" : "Disabled";
			}),
			isSelf: computed(() => {
				return user.value.id === props.tenant.id;
			}),

			name: computed(() => {
				if(!props.tenant) return "";
				const name = props.tenant.name;
				return `${name?.first || ""} ${name?.last || ""}`
			}),

			initials: computed(() => {
				if(!props.tenant) return "";
				const name = props.tenant.name;
				const first = name.first?.substring(0, 1);
				const last  = name.last?.substring(0, 1);
				return `${first}${last}`;
			}),

			maxWidth: computed(() => {
				return settings.dialog.widths[root.$vuetify.breakpoint.name];
			}),
		});


		usePruneQueryParameter(root.$router, "settings");
		useSyncQueryParameter(root.$router, "settings", exists => {
			state.showAccountSettings = exists;
		});
		useDisableDocumentScrollFix(() => !state.showAccountSettings, {immediate:false, delay:150});

		watch(() => state.showAccountSettings, (showAccountSettings) => {
			const { $router, $route } = root;
			const { path, query } = $route;
			const { user } = query;

			if(showAccountSettings) {
				const missingProp = !("settings" in query);
				if(missingProp) $router.push({ path, query:{ user, settings:null } });
			}
		});

		return { ...toRefs(state) };
	}
}
</script>

<style lang="scss" scoped>
	.avatar-container {
		width: 100%;
		height: 200px;
	}

	.tenant-account-settings-dialog {
		.v-toolbar {
			z-index: 10;
		}

		.v-system-bar {
			z-index: 5;
		}
	}
</style>
