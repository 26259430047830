import { onBeforeUnmount } from "@vue/composition-api";

export default function(router, parameterName, callback) {
	const handle = router.beforeEach((to, from, next) => {
		try {
			const exists = parameterName in to.query;
			callback(exists, to.query[parameterName]);
		} catch(err) { console.error(err); }
		next();
	});
	onBeforeUnmount(handle);
}
