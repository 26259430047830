import { setDoc, serverTimestamp, Timestamp } from "firebase/firestore";

export const DisableAccountAction = (tenantSnapshot) => {
	if(!tenantSnapshot) throw new Error("Missing tenant!!!");

	return {
		invoke: (tenants) => {
			const status = { enabled:false, updated_at: serverTimestamp() };
			return setDoc(tenantSnapshot.ref, { status }, { merge:true })
				.then(() => {
					const user = tenants.find(el => el.id === tenantSnapshot.id);
					if(user) {
						user.status = { enabled:false, updated_at: Timestamp.now() };
						return [...tenants];
					}
					return tenants;
				});
		},
	};

};
DisableAccountAction.TYPE = "disableAccount";
