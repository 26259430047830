import { AddUserToResidenceAction, RemoveUserFromResidenceAction, DeleteAccountAction, DisableAccountAction, ElevateAccountAction, EnableAccountAction } from "./actions";

export default function reducer(action = {}) {
	const { type, tenant, residence } = action;
	switch(type) {
		case AddUserToResidenceAction.TYPE:      return AddUserToResidenceAction(tenant, residence);
		case DeleteAccountAction.TYPE:           return DeleteAccountAction(tenant, residence);
		case DisableAccountAction.TYPE:          return DisableAccountAction(tenant);
		case ElevateAccountAction.TYPE:          return ElevateAccountAction(tenant);
		case EnableAccountAction.TYPE:           return EnableAccountAction(tenant);
		case RemoveUserFromResidenceAction.TYPE: return RemoveUserFromResidenceAction(tenant, residence);
		default: throw new Error(`Unsupported action type "${action.type}". Action: ${JSON.stringify(action)}`);
	}
}
