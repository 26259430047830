import { reactive, computed, onUnmounted } from "@vue/composition-api";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";

import { db } from "@/services/firebase";
import useUser from "@/composables/useUser";
import Subject from "@/lib/subject";

export default function() {
	let unsubscribe;
	const state = reactive({
		list: [],
		loading: false,
	});
	const onChange = new Subject();

	const getResidences = (siteId) => {
		if(unsubscribe) unsubscribe();

		const residencesCollection = collection(db, "sites", siteId, "residences");
		const q = query(residencesCollection, orderBy("name"));

		state.loading = true;
		unsubscribe = onSnapshot(q, snap => {
			state.loading = false;
			snap.docChanges().forEach(({type, doc, oldIndex}) => {
				if(type === "added") {
					state.list.push(doc);
				} else if(type === "modified") {
					const before = state.list[oldIndex].data();
					const after = doc.data();
					onChange.next({before, after});
					state.list[oldIndex] = doc;
				} else if(type === "removed") {
					state.list.splice(oldIndex, 1);
				}
			});
			state.list = [...state.list];
		},
			(err) => {
				state.loading = false;
				console.error(err);
			},
		);
	};

	const {adminSite} = useUser();

	onUnmounted(() => unsubscribe?.call());

	const load = () => {
		const siteId = adminSite.value;
		if(!siteId) return;
		return getResidences(siteId);
	};

	return {
		list: computed(() => state.list),
		loading: computed(() => state.loading),
		load,
		onChange$: onChange.asObservable(),
	};
}
