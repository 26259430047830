import orderListBy from "lodash/fp/orderBy";
import { reactive, computed, onUnmounted } from "@vue/composition-api";
import { collection, onSnapshot, query, orderBy, where } from "firebase/firestore";

import { db } from "@/services/firebase";
import useUser from "@/composables/useUser";

export default function() {
	let unsubscribe;
	const state = reactive({
		list: [],
		loading: false,
	});

	const applyChangeToList = (list) => (change) => {
		const data = change.doc.data()
		const invite = { ...data, timestamp:data.timestamp.toMillis(), registration_time:data.registration_time?.toMillis() };
		if(change.type === "added") list.push(invite);
		if(change.type === "removed") list.splice(change.oldIndex, 1);
		if(change.type === "modified") {
			const index = list.findIndex(el => el.key === invite.key);
			if(index != -1) list.splice(change.newIndex, 1, invite);
			else list.push(invite);
		}
	};

	const invitationsCollection = collection(db, "userInvitations");

	const getInvitations = (siteId) => {
		if(unsubscribe) unsubscribe();

		const q = query(invitationsCollection,
			where("site_id", "==", siteId),
			where("hidden", "==", false),
			orderBy("timestamp", "desc"),
		);

		state.loading = true;
		unsubscribe = onSnapshot(q, (snap) => {
			const list = state.list.slice();
			snap.docChanges().forEach(applyChangeToList(list));
			state.list = orderListBy(["timestamp"], ["desc"], list);
			state.loading = false;
		}, console.error);
	};

	const {adminSite} = useUser();

	onUnmounted(() => unsubscribe?.call());

	const load = () => {
		const siteId = adminSite.value;
		if(!siteId) return;
		return getInvitations(siteId);
	};

	return {
		invitations: computed(() => state.list),
		load,
		loading: computed(() => state.loading),
	};
}
