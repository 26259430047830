<template>
	<v-list-item @click="$listeners.click">
		<v-list-item-content>
			<v-list-item-title v-text="room.name"></v-list-item-title>

			<v-list-item-subtitle
				class="list-item-headline text--secondary"
				v-text="occupancy"
			></v-list-item-subtitle>
		</v-list-item-content>
	</v-list-item>
</template>

<script>
import { computed } from "@vue/composition-api";

export default {
	name: "RoomListItem",
	components: {},
	props: { room:Object },
	emits: ["click"],

	setup(props) {
		const occupancy = computed(() => {
			const room = props.room;
			if(room.tenants?.length > 0) return `${room.tenants.length} Occupant(s)`;
			return "Vacant";
		});
		return { occupancy };
	}
}
</script>
