<template>
	<v-dialog persistent
		:max-width="maxWidth"
		:value="open"
		@keydown="!busy && $emit('close')"
	>
		<v-card :loading="busy">
			<template slot="progress">
				<v-progress-linear indeterminate color="primary"/>
			</template>

			<v-card-title class="headline">
				<slot name="title"></slot>
			</v-card-title>

			<v-card-text>
				<slot name="text"></slot>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text
					color="blue darken-1"
					:disabled="busy"
					@click="$emit('cancel')"
				> {{noText}} </v-btn>
				<v-btn text
					color="blue darken-1"
					:disabled="busy"
					@click="$emit('confirm')"
				> {{yesText}} </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { computed } from "@vue/composition-api"
import settings from "@/config/settings";

export default {
	name: "ConfirmationDialog",
	props: {
		open: { type:Boolean, required:true },
		busy: { type:Boolean, default:false },
		yesText: { type:String, default:"Yes" },
		noText: { type:String, default:"No" },
	},
	emits: [ "cancel", "confirm" ],

	setup(_, {root}) {
		const maxWidth = computed(() => {
			return settings.confirmation.widths[root.$vuetify.breakpoint.name];
		});
		return { maxWidth };
	}
}
</script>
