import { setDoc, serverTimestamp, Timestamp } from "firebase/firestore";

export const ElevateAccountAction = (tenantSnapshot) => {
	if(!tenantSnapshot) throw new Error("Missing tenant!!!");

	return {
		invoke: (tenants) => {
			const data = { is_admin:true, updated_at:serverTimestamp() };
			return setDoc(tenantSnapshot.ref, data, { merge:true })
				.then(() => {
					const user = tenants.find(el => el.id === tenantSnapshot.id);
					if(user) {
						user.is_admin = true;
						user.updated_at = Timestamp.now();
						return [...tenants];
					}
					return tenants;
				});
		},
	};

};
ElevateAccountAction.TYPE = "elevateAccount";
