<template>
	<div>
		<v-dialog persistent
			v-if="!!tenant"
			:value="open"
			:fullscreen="$vuetify.breakpoint.smAndDown"
			:hide-overlay="$vuetify.breakpoint.smAndDown"
			:no-click-animation="$vuetify.breakpoint.smAndDown"
			:max-width="maxWidth"
			class="tenant-profile-dialog"
			overlay-color="grey darken-3"
			@keydown.esc="$emit('close')"
		>
			<v-card>
				<!-- Profile Toolbar -->
				<v-toolbar flat dark v-if="window === 'profile'">
					<v-btn icon dark @click="$emit('close')">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Tenant Settings</v-toolbar-title>
				</v-toolbar>

				<!-- Room Select Toolbar -->
				<template v-if="window === 'roomSelect'">
					<v-toolbar flat v-if="searching" dark id="room-search-toolbar" class="px-0">
						<SearchToolbar
							:searchText.sync="searchText"
							@back:clicked="searching = false; searchText = ''"
						/>
					</v-toolbar>

					<v-toolbar flat v-if="!searching" dark>
						<v-btn icon dark @click="window = 'profile';">
							<v-icon>mdi-close</v-icon>
						</v-btn>
						<v-toolbar-title>Select Room</v-toolbar-title>
						<v-spacer/>
						<v-btn icon dark @click="searching = true">
							<v-icon>mdi-magnify</v-icon>
						</v-btn>
					</v-toolbar>
				</template>

				<v-card-text class="pl-0 pr-1">
					<v-window touchless v-model="window">
						<!-- Profile Content -->
						<v-window-item value="profile">
							<TenantProfile
								:tenant="tenant"
								:is-admin="tenant.is_admin"
								:disabled="busy"
								:busy="busy"
								@click:enable="enableAccount"
								@click:disable="disableAccount"
								@click:delete="deleteAccountConfirmDialog = true"
								@click:elevate-admin="elevateAccountConfirmDialog = true"
							>
								<template v-slot:room-actions>
									<v-btn text
										v-if="tenant.residence"
										:disabled="loading"
										:loading="loading"
										color="accent"
										@click="removeRoomConfirmDialog = true"
									> Remove </v-btn>
									<v-btn v-else text
										:disabled="loading"
										:loading="loading"
										color="primary"
										@click="window = 'roomSelect'"
									> Add </v-btn>
								</template>
							</TenantProfile>

						</v-window-item>

						<!-- Room Select Content -->
						<v-window-item value="roomSelect">
							<RoomList
								:list="filteredRooms"
								@click:room="addResidence($event); window='profile'"
							/>
						</v-window-item>
					</v-window>
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- Remove Room Dialog -->
		<ConfirmationDialog
			yesText="Remove"
			noText="Cancel"
			:open="removeRoomConfirmDialog"
			@confirm="removeRoomConfirmDialog = false; removeResidence()"
			@cancel="removeRoomConfirmDialog = false"
		>
			<template v-slot:title> Confirm Removal </template>
			<template v-slot:text>
				Are you sure you want to remove this user from their residence?
				They will no longer be able to modify their temperature controls remotely.
			</template>
		</ConfirmationDialog>

		<!-- Delete Account Dialog -->
		<ConfirmationDialog
			yesText="Delete"
			noText="Cancel"
			:open="deleteAccountConfirmDialog"
			:busy="busy"
			@confirm="deleteAccount()"
			@cancel="deleteAccountConfirmDialog = false"
		>
			<template v-slot:title> Confirm Account Deletion </template>
			<template v-slot:text>
				Are you sure you want to delete this user's account?
				They will no longer have access their temperature controls remotely.
				The user will need to be re-invited to add them back at a later date.
			</template>
		</ConfirmationDialog>

		<!-- Elevate Admin Account Dialog -->
		<ConfirmationInputDialog
			yes-text="Elevate"
			no-text="Cancel"
			match-text="Admin"
			:open="elevateAccountConfirmDialog"
			:busy="busy"
			@confirm="elevateAccount(); elevateAccountConfirmDialog = false"
			@cancel="elevateAccountConfirmDialog = false"
		>
			<template v-slot:title> Confirm Admin Status </template>
			<template v-slot:text>
				<span>Type 'Admin' to confirm elevating {{ name }} to an admin user.</span>
			</template>
		</ConfirmationInputDialog>
	</div>
</template>

<script>
import { reactive, toRefs, computed } from "@vue/composition-api";

import useSnackbar, { CLOSE_ACTION } from "@/composables/useSnackbar";
import useDisableDocumentScrollFix from "@/composables/useDisableDocumentScrollFix";
import { Actions, useTenants } from "@/composables/tenants";
import settings from "@/config/settings";

import TenantProfile from "./TenantProfile";
import SearchToolbar from "@/components/SearchToolbar";
import RoomList from "./RoomList";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import ConfirmationInputDialog from "@/components/ConfirmationInputDialog";


function searchList(list, searchText) {
	let search = searchText;
	if(!search) return list;

	const regex = new RegExp(`.*${search.trim()}.*`, "i");
	return list.filter( item => regex.test(item.get("name")) );
}


export default {
	name: "TenantProfileDialog",
	components: { TenantProfile, RoomList, ConfirmationDialog, ConfirmationInputDialog, SearchToolbar },
	props: {
		open: { type:Boolean, required:true },
		tenant: { type:Object },
		rooms: { type:Array, default:() => [] },
	},
	emits: ["close", "update:user", "update:residence", "delete:user"],

	setup(props, {root, emit}) {
		const snackbar = useSnackbar();
		const { invoke } = useTenants();

		const state = reactive({
			window: "profile",
			searching: false,
			searchText: "",
			filteredRooms: computed(() => {
				const toData = room => ({...room.data(), id:room.id});
				return searchList(props.rooms, state.searchText).map(toData);
			}),
			removeRoomConfirmDialog: false,
			deleteAccountConfirmDialog: false,
			elevateAccountConfirmDialog: false,
			loading: false,
			busy: false,
			maxWidth: computed(() => {
				return settings.dialog.widths[root.$vuetify.breakpoint.name];
			}),
			name: computed(() => {
				if(!props.tenant) return "";
				const name = props.tenant.name;
				return `${name?.first || ""} ${name?.last || ""}`
			}),
		});

		const processResidenceResults = ({user, residence}) => {
			if(user) emit("update:user", user);
			if(residence) emit("update:residence", residence);
		};

		const methods = {
			removeResidence: () => {
				state.loading = true;
				const residence = props.rooms.find(room => room.get("name") === props.tenant.residence.name);

				invoke({type:Actions.RemoveUserFromResidenceAction.TYPE, tenant:props.tenant, residence})
					.catch(err => {
						console.error("removeResidence", err);
						snackbar.show("Failed to remove user to residence!", {action:CLOSE_ACTION});
					});

				processResidenceResults({residence, user:props.tenant});
				snackbar.show("Removed user from residence", {action:CLOSE_ACTION});
				state.loading = false;
			},

			addResidence: ({id}) => {
				state.loading = true;
				const residence = props.rooms.find(room => room.id === id);

				invoke({type:Actions.AddUserToResidenceAction.TYPE, tenant:props.tenant, residence})
					.catch(err => {
						console.error("addResidence", err);
						snackbar.show("Failed to add user to residence!", {action:CLOSE_ACTION});
					});

				processResidenceResults({residence, user:props.tenant});
				snackbar.show("Added user to residence", {action:CLOSE_ACTION});
				state.loading = false;
			},

			deleteAccount: () => {
				state.busy = true;

				let residence = null;
				if(props.tenant.residence) {
					residence = props.rooms.find(room => room.get("name") === props.tenant.residence.name);
				}

				return invoke({type:Actions.DeleteAccountAction.TYPE, tenant:props.tenant, residence})
					.then(() => {
						emit("close");
						emit("delete:user");
					})
					.catch(err => {
						console.error("deleteAccount", err);
						snackbar.show("Failed to delete user account!", {action:CLOSE_ACTION});
					})
					.finally(() => {
						state.busy = false;
						state.deleteAccountConfirmDialog = false;
					});
			},

			enableAccount: () => {
				state.busy = true;
				return invoke({type:Actions.EnableAccountAction.TYPE, tenant:props.tenant})
					.then(() => emit("update:user", props.tenant))
					.finally(() => state.busy = false);
			},
			disableAccount: () => {
				state.busy = true;
				return invoke({type:Actions.DisableAccountAction.TYPE, tenant:props.tenant})
					.then(() => emit("update:user", props.tenant))
					.finally(() => state.busy = false);
			},
			elevateAccount: () => {
				state.busy = true;
				return invoke({type:Actions.ElevateAccountAction.TYPE, tenant:props.tenant})
					.then(() => emit("update:user", props.tenant))
					.finally(() => state.busy = false);
			}
		};

		useDisableDocumentScrollFix(() => props.open, {immediate:true});

		return { ...toRefs(state), ...methods };
	}
}
</script>

<style lang="scss" scoped>
	.avatar-container {
		width: 100%;
		height: 200px;
	}
</style>
