import { writeBatch, arrayUnion } from "firebase/firestore";
import { db } from "@/services/firebase";

export const AddUserToResidenceAction = (tenantSnapshot, residenceSnapshot) => {
	if(!tenantSnapshot) throw new Error("Missing tenant!!!");
	if(!residenceSnapshot) throw new Error("Missing residence!!!");

	return {
		invoke: (tenants) => {
			const batch = writeBatch(db);
			const residenceName = residenceSnapshot.get("name");

			batch.update(tenantSnapshot.ref, {
				residence: { name:residenceName }
			});
			batch.update(residenceSnapshot.ref, {tenants:arrayUnion(tenantSnapshot.id)});

			return batch.commit()
				.then(() => {
					const user = tenants.find(el => el.id === tenantSnapshot.id);
					if(user) {
						user.residence = { ...user.residence, name:residenceName };
						return [...tenants];
					}
					return tenants
				});
		},
	};

};
AddUserToResidenceAction.TYPE = "addToResidence";
