<template>
	<v-list-item @click="$emit('click')">
		<template v-slot:default>
			<v-list-item-content>
				<v-list-item-title v-text="name"></v-list-item-title>

				<v-list-item-subtitle
					class="list-item-headline"
					v-text="residence"
				></v-list-item-subtitle>
			</v-list-item-content>

			<v-list-item-icon v-if="isAdmin" class="mt-5 mb-3">
				<v-icon color="blue darken-1">mdi-security</v-icon>
			</v-list-item-icon>
		</template>
	</v-list-item>
</template>

<script>
import { computed } from "@vue/composition-api";

export default {
	name: "TenantListItem",
	components: { },
	props: {
		tenant: { type:Object, required:true },
		isAdmin: { type:Boolean, default:false },
	},

	setup(props) {
		const name = computed(() => `${props.tenant.name?.first || ""} ${props.tenant.name?.last || ""}`);
		const residence = computed(() => props.tenant.residence?.name || "--Unassigned--");
		return { name, residence };
	}
}
</script>
