<template>
	<div class="d-flex flex-grow-1">
		<slot name="no-items" v-if="!loading && list.length === 0"/>

		<v-list two-line class="flex-grow-1" v-else>
			<v-slide-x-transition group>
				<template v-for="(tenant, index) in list">
					<TenantListItem
						:tenant="tenant"
						:is-admin="tenant.is_admin === true"
						:key="tenant.id"
						@click="$emit('click:tenant', tenant)"
					/>
					<v-divider :key="index"></v-divider>
				</template>
			</v-slide-x-transition>

			<!-- Loading Feedback -->
			<template v-if="loading">
				<template v-for="n in 8">
					<v-skeleton-loader
						:key="`skel-${n}`"
						v-if="loading"
						max-width="200px"
						type="list-item-two-line"
						class="py-n2"
					/>
					<v-divider v-if="n < 8" :key="n"></v-divider>
				</template>
			</template>

		</v-list>
	</div>
</template>

<script>
import TenantListItem from "./TenantListItem";

export default {
	name: "TenantList",
	components: { TenantListItem },
	props: {
		list: { type:Array },
		loading: { type:Boolean, default:false },
	},
}
</script>
