import sortBy from "lodash/fp/sortBy";
import { reactive, computed } from "@vue/composition-api";
import { doc, getDoc, getDocs, query, where, orderBy, limit, startAfter } from "firebase/firestore";

import { usersCollection } from "@/services/firebase";
import useUser from "@/composables/useUser";
import reducer from "./reducer";

const userIdToIndexMap = new Map();
const state = reactive({
	list: [],
	loading: false,
});

let history = [];
let lastDocFromQuery;
let userQuery = query(usersCollection,
	orderBy("name.last"),
	orderBy("name.first"),
	limit(20),
);


const { adminSite } = useUser();

export default function() {
	const load = ({count} = {}) => {
		const siteId = adminSite.value;
		if(!siteId) return;
		return getUsers(siteId, count);
	};

	const get = (userId) => {
		const siteId = adminSite.value;
		if(!siteId) return;

		const docRef = doc(usersCollection, userId);
		return getDoc(docRef).then(snap => {
			const list = state.list.slice();
			addUserToList(list, userIdToIndexMap, snap);
			const index = userIdToIndexMap.get(userId);
			state.list = sortBy(["name.last", "name.first"], list);
			return list[index];
		});
	};

	const computeIndexMap = () => {
		userIdToIndexMap.clear();
		state.list.forEach((user, i) => userIdToIndexMap.set(user.id, i));
	};

	const addUserToList = (list, indexMap, doc) => {
		const user = { ...doc.data(), id:doc.id, ref:doc.ref };
		if(user.residence === undefined) user.residence = null; // allows changes to be seen from UI, TODO maybe a converter would be better here

		const index = userIdToIndexMap.get(user.id);
		if(index !== undefined) {
			list[index] = user;
		} else {
			indexMap.set(user.id, list.length);
			list.push(user);
		}
	};

	const getUsers = (siteId, count) => {
		state.loading = true;

		userQuery = query(userQuery, where("site_id", "==", siteId));
		if(count && count > 0) userQuery = query(userQuery, limit(count))
		if(lastDocFromQuery) userQuery = query(userQuery, startAfter(lastDocFromQuery));

		return getDocs(userQuery)
			.then(snap => {
				const list = state.list.slice();
				snap.docs.forEach(addUserToList.bind(null, list, userIdToIndexMap));

				lastDocFromQuery = snap.docs[snap.docs.length - 1];
				state.list = sortBy(["name.last", "name.first"], list);
				computeIndexMap();
			})
			.catch(console.error)
			.finally(() => state.loading = false);
	};

	const invoke = async action => {
		const command = reducer(action);
		if(!command) throw new Error(`Invalid tenant action: "${action.type}"`);

		state.list = await command.invoke(state.list);
		computeIndexMap();
		history.push(command);
	};

	const search = (value) => {
		const queryFirstName = query(usersCollection,
			where("name.first", ">=", value),
			where("name.first", "<=", value + "\uf8ff"),
			where("site_id", "==", adminSite.value),
		);
		const queryLastName = query(usersCollection,
			where("name.last", ">=", value),
			where("name.last", "<=", value + "\uf8ff"),
			where("site_id", "==", adminSite.value),
		);

		return Promise.all([getDocs(queryFirstName), getDocs(queryLastName)])
			.then((results) => {
				const [byFirstName, byLastName] = results;
				if(byFirstName.empty && byLastName.empty) return;

				const list = state.list.slice();
				byFirstName.docs.forEach(addUserToList.bind(null, list, userIdToIndexMap));
				byLastName.docs.forEach(addUserToList.bind(null, list, userIdToIndexMap));
				state.list = [...list];
			});
	};

	return {
		list: computed(() => state.list),
		loading: computed(() => state.loading),
		load,
		get,
		invoke,
		search,
	};
}

export const reset = () => {
	state.list = [];
	state.loading = false;
	history = [];
	lastDocFromQuery = null;
	userIdToIndexMap.clear();
};
